import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import email from '../img/email-icon.svg'
import phone from '../img/phone-icon.svg'
import ContactForm from '../components/ContactForm'
import Helmet from 'react-helmet'

// eslint-disable-next-line
export const ContactPageTemplate = ({
  title,
  description,
  personOne,
  siteTitle,
  formTitle,
  formDescription,
  formLink,
}) => {
  return (
    <section>
      <div className="container flex flex-col">
        <Helmet>
          <title>{`${title} | ${siteTitle || 'Content Manager'}`}</title>
          <meta name="description" content={`${description}`} />
          <meta property="og:title" content={`${title} | ${siteTitle}`} />
          <meta property="og:description" content={`${description}`} />
        </Helmet>
        <h1 className="purple-text mt-10 mb-8 text-4xl text-purple font-semibold font-playfair md:mt-20 md:text-5xl md:mb-18 lg:text-7xl lg:mb-24">
          {title}
        </h1>
        <p className="mb-8 md:text-xl lg:mb-14">{description}</p>
        <div className="flex flex-col lg:flex-row lg:mb-10">
          <div className="flex flex-col lg:flex-1 lg:mr-10">
            <div className="flex items-center mb-8">
              <div
                className="mr-4 max-w-[111px] md:max-w-[180px] md:mr-8"
                style={{ flex: 1 }}
              >
                <PreviewCompatibleImage imageInfo={personOne.profileImage} />
              </div>
              <div className="flex flex-col">
                <h3>{personOne.name}</h3>
                <div className="flex items-center">
                  <img
                    src={phone}
                    alt="Phone"
                    className="mr-2 w-[15px] h-[18px] md:w-[20px] md:h-[24px]"
                  />
                  <a className="md:text-xl" href={`tel:${personOne.phone}`}>
                    {personOne.phone}
                  </a>
                </div>
                <div className="flex items-center">
                  <img
                    src={email}
                    alt="Email"
                    className="mr-2 w-[15px] h-[18px] md:w-[20px] md:h-[24px]"
                  />
                  <a className="md:text-xl" href={`mailto:${personOne.email}`}>
                    {personOne.email}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-10 lg:flex-1 md:mb-16">
            <ContactForm />
          </div>
        </div>

        <div className="google-map mb-8 md:mb-12 lg:mb-20">
          <iframe
            title="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.5622591532356!2d-0.15505908477798502!3d51.52124641751255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ace1e400fad%3A0xe01424fb58beb287!2s17%20Nottingham%20St%2C%20London%20W1U%205EW!5e0!3m2!1sen!2suk!4v1647955094979!5m2!1sen!2suk"
            width="100%"
            height="auto"
            style={{ border: 0 }}
          ></iframe>
        </div>
        {/* Registration Form Below */}
        <h3 className="mb-4 ld:mb-8">{formTitle}</h3>
        <div className="md:mb-12">
          <p className="mb-8 md:text-xl lg:mb-14">
            {formDescription}{' '}
            <a
              style={{
                textDecoration: 'underline',
                textDecorationColor: '#d39f4a',
              }}
              title="registration-form"
              href={formLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  personOne: PropTypes.object.isRequired,
  formTitle: PropTypes.string.isRequired,
  formDescription: PropTypes.string.isRequired,
  formLink: PropTypes.string.isRequired,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ContactPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        personOne={frontmatter.personOne}
        formTitle={frontmatter.formTitle}
        formDescription={frontmatter.formDescription}
        formLink={frontmatter.formLink}
        siteTitle={data.site.siteMetadata.title}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        personOne {
          profileImage {
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: NONE
                )
              }
            }
          }
          name
          phone
          email
        }
        formTitle
        formDescription
        formLink
      }
    }
  }
`
