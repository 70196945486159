import * as React from 'react'
import { navigate } from 'gatsby-link'
import Button from '../components/Button'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <div className="contact-form">
        <form
          className="border-2 purple-border border-purple py-8 px-4 lg:px-8"
          name="contact"
          method="post"
          action="/contact/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div className="field">
            <label className="label md:text-xl" htmlFor={'name'}>
              Full name
            </label>
            <div className="control mt-2 mb-2">
              <input
                className="input w-full"
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                id={'name'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label md:text-xl" htmlFor={'email'}>
              Email
            </label>
            <div className="control mt-2 mb-2">
              <input
                className="input w-full"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label md:text-xl" htmlFor={'subject'}>
              Subject
            </label>
            <div className="control mt-2 mb-2">
              <input
                className="input w-full"
                type={'text'}
                name={'subject'}
                onChange={this.handleChange}
                id={'subject'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label md:text-xl" htmlFor={'phone'}>
              Phone Number
            </label>
            <div className="control mt-2 mb-2">
              <input
                className="input w-full"
                type={'text'}
                name={'phone'}
                onChange={this.handleChange}
                id={'phone'}
                required={false}
              />
            </div>
          </div>
          <div className="field">
            <label className="label md:text-xl" htmlFor={'message'}>
              Message
            </label>
            <div className="control mt-2 mb-2">
              <textarea
                className="textarea w-full"
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                required={true}
              />
            </div>
          </div>
          <div className="field mt-8 mb-2 flex justify-center">
            {/* <button className="button is-link" type="submit">
              Submit
            </button> */}
            <Button type="submit" name="Submit" />
          </div>
        </form>
      </div>
    )
  }
}
